.settingsContainer {
    margin-top: 0.5rem;
    padding: 5px;
}

.radioGroupBtn {
    margin-bottom: 1rem;
}

.settingsContainerAnimal {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
}

.itemListContainer {
    display: flex;
    flex-basis: 20%;
    min-height: 85.5vh;
    max-height: 85.5vh;
}

.itemList {
    overflow-y: auto;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    border: solid 1px #81D000 !important;
    border-radius: 0;
}

.itemCardCreate {
    display: flex;
    width: 90%;
    margin-left: 1rem;

}
.itemMarkCreate {
    display: flex;
    width: 90%;
    margin-left: 1rem;

}
.itemPerimetrCreate {
    display: flex;
    width: 90%;
    margin-left: 1rem;

}
.textHeaderAddPerimetr {
    text-align: center;
}

.textHeaderAddPerimetr {
    font-weight: 600;
    font-size: 24px;
}
@media screen and (max-width : 1024px) {
    .itemCardCreate {
        order: 1;
    }
    .itemPerimetrCreate {
        margin-bottom: 5rem;
    
    }
    .itemMarkCreate{
        margin-bottom: 1rem;
    }
    .itemListContainer {
        order: 2;
        border: none;
    }

    .settingsContainerAnimal {
        flex-direction: column;
    }

    .itemListContainer {
        margin-top: 1rem;
        display: flex;
        flex-basis: 20%;
        min-height: 20rem;
        overflow-y: auto;
    }

}