.animalPageFlex {
    padding: 5px;
}

.headerAnimalPageFlex {
    display: flex;
    justify-content: space-around;
    flex-basis: 100%;
    padding: 0px 0px 5px 0px;
}

.headerSearchAnimalPageFlex {
    width: 17.6%;

}

.headerEditAnimalPageFlex {
    display: flex;
    justify-content: end;
    width: 85%;
}

.bodyAnimalPageFlex {
    flex-basis: 100%;
    display: flex;
    height: 87.5vh;
}

.listAnimalPageFlex {
    width: 20%;
}

.detailsAnimalPageFlex {
    width: 100%;
    margin-left: 0.5rem;
    margin-top: 0.4em;

}

.inputSearch {
    border: solid 1px #81D000;
    border-radius: 0px !important;
    padding-left: 5px;
    width: 96.8%;
}

.btnSearch {
    border: solid 1px #81D000;
    justify-content: center;

    width: 15%;
    text-align: center;
}

.btnEditAnimal {
    color: #285574;
    margin-left: 0.5rem;
    margin-right: 1rem;
}

.btnEditAnimal:hover {
    color: #81D000;

}

.hidden {
    display: none;
}

/* @media screen and (max-width : 1024px), screen and (max-height: 600px) {
    .listAnimalPageFlex {
        max-height: 600px;
        min-height: 600px;
     
    }
    .listAnimal {
        max-height: 600px;
        min-height: 600px;
     
    }
    .listAnimalIsEdit {
        max-height: 570px;
        min-height: 570px;
    }

} */
@media screen and (max-width : 1024px) {
    .headerSearchAnimalPageFlex {
        width: 30%;
    }

    .headerEditAnimalPageFlex {
        width: 70%;
    }

    .detailsAnimalPageFlex {
        width: 100%;
        margin-left: 0rem;
        margin-top: 0.5em;
        height: auto;
    }
}

@media screen and (max-width : 920px) {
    .inputSearch {
        border: solid 1px #81D000;
        border-radius: 0px !important;
        padding-left: 5px;
        width: 96%;
    }

    .detailsAnimalPageFlex {

        margin-top: 0.5em;
    }

    .bodyAnimalPageFlex {
        flex-direction: column;
    }

    .listAnimalPageFlex {
        width: 100%;
    }


}

@media screen and (max-width : 900px) and (max-height: 510px) and (orientation: landscape) {


    .listAnimalPageFlex {
        flex-basis: 100%;
   
    }

    .detailsAnimalPageFlex {
        max-width: 100%;
        margin-left: 0.5rem;
        margin-top: 0.4em;
    }
}

@media screen and (max-width: 600px) {
    .headerSearchAnimalPageFlex {
        width: 50%;
        margin-left: 0;
    }

    .headerEditAnimalPageFlex {
        width: 50%;
    }
}

@media screen and (max-width : 320px) {
    .headerSearchAnimalPageFlex {
        visibility: hidden;
    }
}