 * {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   max-height: 100vh;
 }


 /*
@import "~materialize-css/dist/css/materialize.min.css";
 html,
body {
  background-color: red;
  display: grid;
  min-height: 100vh;
    font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.75;
  overflow-x: hidden;
  opacity: 1;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
*/
 .ant-layout {
   position: flex !important;

   font-size: 14px;
   min-width: 320px;
   position: relative;

   opacity: 1;
   font-family: Roboto, "Helvetica Neue", sans-serif;
 }

 @media screen and (max-width : 1024px) {
 

 }
 @media screen and (max-width : 920px) {
 

 }
 @media screen and (max-width : 800px) {}

 @media screen and (max-width : 420px) {}

 @media screen and (max-width: 600px) {}