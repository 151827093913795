.tasksComponent {
    overflow: hidden;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 0px 7px 0px 7px;
}

.taskAnimalHeader {
    display: flex;
    font-weight: bold;
    font-size: 18px;
}

.listItem {
    padding: 0.3rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.listItem:hover {
    background-color: #81D000;

}

.textAreaTask {
    border-radius: 0px;
}

.textTask {
    width: 100%;
}

.textTask :focus {
    border: solid 1px #81D000 !important;
}

.textTask :hover {
    border-color: #81D000 !important;
}

.listTaskCont {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

}

.listTaskCont::-webkit-scrollbar {
    width: 3px;
    background-color: #f9f9fd;
}

.listTaskCont::-webkit-scrollbar-thumb {

    background-color: #81D000;
}

.listTaskCont::-webkit-scrollbar-track {

    background-color: #f9f9fd;
}

.hidden {
    display: none;
}

.btnTask {
    border-radius: 0px;
    background-color: #285574;
    color: white;
}

.btnTask:hover {
    background-color: #81D000 !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    color: white;
}

.btnTaskContainer {
    display: flex;
}

@media screen and (max-width : 1024px) {
    .task {
 
    }
}

@media screen and (max-width : 800px) {
    .btnTaskContainer {
        justify-content: center;
    }

    .taskAnimalHeader {
        justify-content: center;
    }

    .tasksComponent {
        padding: 1rem;
    }
 
}

@media screen and (max-width : 720px) {
    .userName {
        visibility: hidden;
    }
}

@media screen and (max-width : 420px) {
    .userName {
        visibility: hidden;
    }
}

@media screen and (max-width: 600px) {}