.chartsContainer {

    height: 90%;

}

.notDevice {
    text-align: center;
    margin-top: 1rem;
    color: red;
    font-size: 24px;
}

@media screen and (max-width : 920px) {
    .chartsContainer {
        margin-top: 5rem;
        height: 400px;

    }
}