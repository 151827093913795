.imageAnimal {
    display: flex;
    max-width: 200px;
    text-align: center;
    max-height: 150px;
    margin-bottom: 1rem;
}
.cardAnimal {
    min-height: 100%;   
    padding: 7px!important;
}
.inputLabel {
    display: flex;
    justify-content: space-between;
}

.headerCardAnimal {
    font-size: 18px;
    font-weight: bold;

}

.imageContainer {
    min-height: 150px;
    max-height: 150px;
    display: flex;
    justify-content: center;
    margin-left: 2rem;
}

.animalImgButtonContainer {
    display: flex;
    justify-content: center;
}

.btnEditAnimal {
    margin-left: 1rem;

    color: #285574;
}

.btnEditAnimal:hover {
    color: #81D000;


}

.hidden {
    visibility: hidden;
}

.dividerAnimalCard {
    background-color: #81D000;

}

@media screen and (max-width : 1024px) {}

@media screen and (max-width : 800px) {
    .inputLabel {
        padding: 0rem 1rem 0rem 1rem;
    }

    .cardAnimal {
        margin-top: 1rem;
        text-align: center;

    }
}

@media screen and (max-width : 720px) {}

@media screen and (max-width : 420px) {}

@media screen and (max-width: 600px) {

    .imageAnimal {
        margin-left: 10%;
    }

    .btnEditAnimal {
        margin-left: 8%;
    }
}