#notfound .notfoundbg {
    position: absolute;
    width: 100%;
    background-size: cover;
  }
  
  #notfound .notfoundbg:after {
    content: '';
    width: 100%;

    background-color: rgba(0, 0, 0, 0.25);
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  #notfound .notfound:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50% , -50%);
        -ms-transform: translate(-50% , -50%);
            transform: translate(-50% , -50%);
    width: 100%;
    height: 600px;
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-box-shadow: 0px 0px 0px 30px rgba(255, 255, 255, 0.7) inset;
            box-shadow: 0px 0px 0px 30px rgba(255, 255, 255, 0.7) inset;

  }
  
  .notfound {
    max-width: 600px;
    width: 100%;
    text-align: center;
    line-height: 1.4;
  }
  
  .notfound .notfound404 {
    position: relative;
    height: 200px;
  }
  
  .notfound .notfound404 h1 {
    font-family: 'Passion One', cursive;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 220px;
    margin: 0px;
    color: #222225;
    text-transform: uppercase;
  }
  
  .notfound h2 {
    font-family: 'Muli', sans-serif;
    font-size: 26px;
    font-weight: 400;
    text-transform: uppercase;
    color: #222225;
    margin-top: 26px;
    margin-bottom: 20px;
  }
  
  .notfound-search {
    position: relative;
    padding-right: 120px;
    max-width: 420px;
    width: 100%;
    margin: 30px auto 20px;
  }
  
  .notfound-search input {
    font-family: 'Muli', sans-serif;
    width: 100%;
    height: 40px;
    padding: 3px 15px;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    background: #222225;
    border: none;
  }
  
  .notfound-search button {
    font-family: 'Muli', sans-serif;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 120px;
    height: 40px;
    text-align: center;
    border: none;
    background: #ff00b4;
    cursor: pointer;
    padding: 0;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
  }
  
  .notfound a {
    font-family: 'Muli', sans-serif;
    display: inline-block;
    font-weight: 400;
    text-decoration: none;
    background-color: transparent;
    color: #222225;
    text-transform: uppercase;
    font-size: 14px;
  }
  
  .notfoundsocial {
    margin-bottom: 15px;
  }
  .notfoundsocial>a {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #222225;
    margin: 3px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
  }
  .notfoundsocial>a:hover {
    color: #fff;
    background-color: #ff00b4;
  }
