.changePassword {
    display: flex;
    padding: 1rem;
    background-color: #ffe961;
    font-size: medium !important;
    color: rgb(0, 0, 0) !important;
 
}

.ContainerInput{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.textPasswordChange {
    font-weight: bold;
}

@media screen and (max-width : 1024px) {
}

@media screen and (max-width : 800px) {}

@media screen and (max-width : 720px) {
 
}

@media screen and (max-width : 420px) {
}

@media screen and (max-width: 600px) {
    
}