.containerAuth {
    background-image: url('../../assets/static/iot5.png');
    display: grid;
    grid-template-columns: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;

}

.loginForm {
    background-color: rgba(245, 245, 245, 0.75);
    margin: 0 auto;
    padding: 2rem;
    height: 65%;
}
@media screen and (max-width : 1024px) {
    .loginForm{
        width: 60%;
        height: 55%;
    }
}
@media screen and (max-width : 912px) {
    .containerAuth {
        background-image: url('../../assets/static/iotMobile.png');
    }
    .loginForm{
        width: 65%;
        height: 45%;
    }
}
@media screen and (max-width : 900px) and (max-height: 510px) and (orientation: landscape) {
    .loginForm {
        height: 100%;
    }
    .containerAuth {
        background-image: url('../../assets/static/iotTablet.png');
    }
}

@media screen and (max-width : 720px) {
    .loginForm{
        width: 65%;
        height: 55%;
    }
  
}
@media screen and (max-width : 420px) {
    .loginForm{
        width: 75%;
        height: 65%;
    }
}
@media screen and (max-width: 600px) {
}