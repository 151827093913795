.modalCard {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background: rgba(0, 0, 0, 0.26);
}


.modalCard.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalCardContent {
    
    padding: 5px;
    background: white;

    min-width: 250px;

}

@media screen and (max-width : 1024px) {

}
@media screen and (max-width : 800px) {
  
}
@media screen and (max-width : 720px) {
    .userName {
        visibility: hidden ;
    }
}
@media screen and (max-width : 420px) {
    .userName {
        visibility: hidden ;
    }
}
@media screen and (max-width: 600px) {

}