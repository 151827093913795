.btnAddAnimal {
    background-color: #285574!important;
}
.btnAddAnimal:hover {
    background-color: #81D000 !important;
}

.textHeaderAddAnimal {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.btnCancelAddAnimal { 
    margin-right: 1rem;
}