.btnGroup{
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-basis: 100%;
}
.btnL {
    background: rgb(117, 190, 0) !important;
    color: white !important;
    border: 1px solid black;
    width: 12rem!important;
    height: 3rem;
    border-radius: 0;
    margin-bottom: 1.5rem;
}
.btnL1{
    margin-left: 1.5rem;
}
.btnL:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.726);
    background: rgb(66, 83, 240) !important;
   
}

.btnR {
 
    background: rgb(40, 85, 116) !important;
    color: white !important;
    border: 1px solid black;
    width: 8rem!important;
    margin-left: 3.5rem;
    border-radius: 0;
}

.btnR:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.726);
    background: rgb(173, 71, 3) !important;
}

.inputEmail {
    background: rgba(252, 247, 225, 0.973) !important;
    border: 1px solid black;
    border-radius: 0;
}
.inputEmail:hover {
    background: rgba(199, 242, 243, 0.973) !important;
}
.imgLogo {
    margin-bottom: 1rem;
    width: 240px;
    height: 52px;
}

.authPage{ 
    display: flex;
    justify-content: space-around;
    height: auto;
   
}

.spinnerPlace {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.spinner {

    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: transparent;
    border: solid #285574 3px;
    animation: round 1s linear infinite;
}

.spinner::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 135px;
    height: 135px;
    border-radius: 50%;
    border-top: #81D000 5px solid;
}
.spinnerText {
    font-size: large;
    font-weight: 600;
}
@keyframes round {
    from {
        rotate: 0deg;
    }

    to {
        rotate: 360deg;
    }
}
@media screen and (max-width : 900px) and (max-height: 510px) and (orientation: landscape) {


 

}