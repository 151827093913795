.headerAnimalList {
    display: flex;
    margin: 0 !important;
    font-weight: 600;
    font-size: large;

}

.ant-list-header {
    background-color: red !important;
}

.listAnimal {
    overflow-y: auto;
    margin-top: 5px;
    padding-bottom: 15px !important;
    min-height: 99.4%;
    max-height: 99.4%;
    border: solid 1px #81D000 !important;
    border-radius: 0;

}

.listAnimalIsEdit {
    overflow-y: auto;
    margin-top: 5px;

    padding-bottom: 15px !important;
    max-height: 95.3%;
    min-height: 95.3%;
    border: solid 1px #81D000 !important;
    border-radius: 0;
}

.listAnimalItem {
    display: flex;
    justify-content: space-between;
    padding: 3px !important;
  
}

.titleAnimal {
    text-align: left;
    width: 250px !important;
    border-radius: 0;
    padding: 0px;
    margin-left: -10px;
}
.titleAnimalPerimetr {
    text-align: left;
    width: 250px !important;
    border-radius: 0;
    padding: 0px;
    margin-left: 10px; 
}
.titleAnimalIsEdit{
    text-align: left;
    width: 220px !important;
    border-radius: 0;
    padding: 0px;
    margin-left: -10px;  
}
.selectedAnimal {
    padding-left: 15px;
    padding-right: 15px;
    background-color: #81D000 !important;
}

.titleAnimal:focus {
    padding-left: 15px;
     padding-right: 15px;
    background-color: #81D000 !important;
}

.titleAnimal:hover {
    background-color: #285574 !important;
    color: white !important;
    padding-left: 15px;
}

/* .titleAnimal:focus {
    text-align: left;
    min-width: 200px !important;
    background-color: #81D000!important;
    color: white!important;
} */
.btn {
    box-shadow: none;
    justify-content: center;
    padding-top: 5px;
}

.btn:hover {
    background-color: #81D000 !important;
}

.btnCreate {
    background-color: #287428;
}

.btnMark {
    background-color: #74285d;
}

.btnStatistic {
    background-color: #9e571d;
}

.hidden {
    display: none;
}

.btnContainer {
    display: flex;
    justify-content: space-around;
}

.spinnerPlace {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.imgLogo {
    margin-bottom: 1rem;
    width: 240px;
    height: 52px;
}

.spinner {

    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: transparent;
    border: solid #285574 3px;
    animation: round 1s linear infinite;
}

.spinner::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 135px;
    height: 135px;
    border-radius: 50%;
    border-top: #81D000 5px solid;
}

@keyframes round {
    from {
        rotate: 0deg;
    }

    to {
        rotate: 360deg;
    }
}

@media screen and (max-width : 920px) {
    .listAnimal {
        max-height: 200px;
        min-height: 200px;
        margin-top: 0px;
    }

    .listAnimalIsEdit {
        max-height: 200px;
        min-height: 200px;
    }
}

@media screen and (max-width : 900px) and (max-height: 510px) and (orientation: landscape) {
    .listAnimal {
        min-height: 88%;
        max-height: 88%;
    }
    .listAnimalIsEdit {

        min-height: 80%;
        max-height: 80%;
    }
    .btnContainer {

        justify-content: space-between;
    }
} 