.ant-layout-sider {
    position: absolute !important;
    z-index: 2;
    height: 100%;
    opacity: 0.95;
}
.ant-layout-content {

}
.ant-menu {
    z-index: 1 !important;
    padding-top: 1rem;
    height: 100%;
    background: #285574 !important;
    opacity: 0.9;
}

.ant-layout-sider-zero-width-trigger {

    background-color: #ffffff !important;
    color: #002140 !important;

    font-size: 2rem !important;
    top: -45px !important;
    left: 10px !important;
}

.ant-layout-sider-zero-width-trigger-left {}

.ant-menu-item:hover {
    background-color: #81D000;
}

.anticon-bars {
    font-weight: 200;
}

.ant-layout-sider-zero-width-trigger:hover {
    background-color: #81D000;
    color: #fff;
}

.ant-layout-header {
    height: 3rem;
    display: flex;
    justify-content: flex-start;
}

@media screen and (max-width : 1024px) {
    .ant-layout-sider {
        height: 100%;
    }
}

@media screen and (max-width : 900px) and (max-height: 510px) and (orientation: landscape) {
    .ant-menu {
        min-height: 166vh !important;
    }
}
@media screen and (max-width : 800px) {
    .ant-layout-header {
        justify-content: center;
    }
}



@media screen and (max-width: 600px) {
    .ant-picker-panels {
        max-width: 300px;
        overflow-x: auto;
    }
}