.modal {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active {
    opacity: 1;
    pointer-events: visibleStroke;
}

.modalContent {
    padding: 20px;
    border-radius: 0px;
    background-color: white;
    height: auto;
    width: 50vw;
    transform: scale(0.5);
    transition: 0.4s all;
}
.modalContent.active {
    transform: scale(1);
}

@media screen and (max-width : 1024px) {
    .modalContent {
        width: 90vw;
        height: 80vh;
        overflow-y: scroll;
    }

}