.animalListHeader {
    font-weight: bold;
    text-align: center;
}

.detailAnimalPageFlex {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    min-height: 100%;
    max-height: 700px;
    margin: 0 !important;
}

.detailCardTaskAnimalPageFlex {
    display: flex;
    flex-basis: 25%;
    flex-direction: column;
    border: solid 1px #81D000;
    padding: 5px;

}

.detailTaskAnimalPageFlex {

    overflow-y: auto;
}

.detailMapAnimalPageFlex {

    width: 100%;
    margin-left: 0.5rem;

    /* border: solid 1px #81D000; */
}

.flexMap {
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 100%;
}


@media screen and (max-width : 1024px) {
    .detailAnimalPageFlex {
        flex-direction: column;
        height: 100vh;
    }
}

@media screen and (max-width : 920px) {
    .detailMapAnimalPageFlex {
        order: 1;
        margin-left: 0;
        width: 100%;
    }
.detailCardAnimalPageFlex {
    margin-top: 3rem;
}
    .detailCardTaskAnimalPageFlex {
        order: 2;
        margin-top: 1rem;
        border: none;
    }

    .itemListContainer {
        order: 2;
    }

    .collapseAnimalCard {
        width: 100%;
        margin-top: 10px;
    }

    .animalListHeader {
        margin-top: 0.5rem;
    }
}

@media screen and (max-width : 900px) and (max-height: 510px) and (orientation: landscape) {
    .detailAnimalPageFlex {
        flex-direction: row;

    }
    .detailMapAnimalPageFlex {
        order:2;
        margin-left: 0;
        width: 40%;
    }

    .detailCardTaskAnimalPageFlex {

        order: 1;
        margin-top: 1rem;
        border: none;
    }

    .itemListContainer {
        order: 1;
    }

}