.userPageContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
    border: solid 1px #81D000;
    width: 30%;
}

.userCardText{
    font-weight: bold;
}
.itemFlexContainer{
    padding:1rem;
    box-sizing: border-box;
    

    display:flex;
}
.itemFlexContainer>div {
    flex: 1 0 0;
}
.userMode {
    margin-left: 1rem;
}
.userModeGreen{ 
    color: green;
}
.userModeRed{ 
    color: red;
}
@media screen and (max-width : 920px) {
    .userPageContainer{
        justify-content: space-around;
        width: 90%;
    }
    .tooltipVisible {
        visibility: hidden;
    }
   
}

@media screen and (max-width : 720px) {}

@media screen and (max-width: 600px) {
}

@media screen and (max-width : 320px) {
}