.center {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
}

.form {
    padding: 40px;
    width: 400px;

    border: 2px solid teal;
    display: flex;
    flex-direction: column;
}

.form input {
    min-width: 300px;
    width: 100%;
    padding: 5px 10px;
    border: teal 1px solid;
}

.form button {
    margin-top: 15px;
    margin-left: auto;
    border: teal 1px solid;
    background: none;
    padding: 10px 15px;
    font-weight: bold;
    color: teal;
    cursor: pointer;
}

.form button:focus,
.form input:focus {
    outline: none;
}

.connection_message {
    width: 400px;
    text-align: center;
}

.message {
    width: 90%;
    border: 2px solid teal;

    padding: 15px;
    margin-bottom: 1rem;
}

.btnDelete {
    float: right;
}


.headerStat {
    padding-top: 1rem;
    text-align: center;
}

.inWork {
    font-size: large;
    font-weight: 600;
    color: red;
}

.statContainer {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem;
}


.panelStat {
    background-color: #285574;
    font-size: medium;
    font-weight: 600;
    margin: 0.5rem;
    color: white;
}

.panelItemTextHeader{
    color: white;
}


.panel1Inst {
    display: flex;
    justify-content: center;

    min-height: 40rem;
    max-height: 40rem;
    overflow-y: auto;
}

.panelItemText {
    margin-bottom: 1rem;
    font-size: medium;
    font-weight: 500;
}

.panelItemFile {
    margin-bottom: 1rem;

}