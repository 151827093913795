.addPerimetr {
    width: 100%;
}

/* .mapSettings {
    overflow-x: auto;
    width: 100%;
    max-height: 460px;
}

.textHeaderAddPerimetr {
    text-align: center;
}

.textHeaderAddPerimetr {
    font-weight: 600;
    font-size: 24px;
} */

