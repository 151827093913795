 .headerLayout {
     display: flex;
     z-index: 1;
     box-shadow: 0 0 10px rgba(0, 0, 0, 1);
     background-color: rgb(255, 255, 255);
     position: 'fixed';
     width: 100%;
 }

 .menuItem:hover {
     background-color: #81D000 !important;
     border-radius: 0;
 }



 .btnLanguage {
     border-radius: 0px;
 }

 .logo {
     display: flex;
     padding: 5px;
     padding-bottom: 10px;
     margin: 0.5rem;
 }

 .btnExit {
     background-color: #285574;
 }

 .btnExit:hover {
     background-color: #81D000;
     box-shadow: 0 0 10px rgba(0, 0, 0, 1);
 }

 .btnExitContainer {
     text-align: right;
 }


 .linkMenu {
     color: #ffffff !important;
 }

 .dividerAside {
     background-color: #81D000;

 }

 .headerIcon {
     margin-right: 0.5rem;
 }

 .footerAsn {
     bottom: 0;
     position: fixed;
     width: 100%;
     height: 20px;
     margin: 0;
     padding: 0;
     text-align: center;
     background-color: #285574;
     color: white;
 }

 .contContainer {
    height:'100%';
    background-color: white;
 }

 .headerFlex {
     display: flex;
     justify-content: start;
 }


 .internationalizing {
     display: flex;
     flex-wrap: nowrap;
     flex-basis: 100%;
     justify-content: space-between;
     margin: -0.5rem;

 }

 .internationalizingHidden {
     visibility: hidden;
 }

 .internationalizingBtn {
     display: flex;
     margin-right: -2.5rem;
     margin-top: 1rem;
 }


 @media screen and (max-width : 1024px) {

     /* .footerAsn { 
        visibility: hidden;
    } */

     .footerAsn {
         z-index: 10;
     }
 }
 @media screen and (max-width : 900px) and (max-height: 510px) and (orientation: landscape) {
  
}

 @media screen and (max-width : 420px) {
     .internationalizing {
         display: flex;
         flex-wrap: nowrap;
         flex-basis: 100%;
         justify-content: space-between;
         margin: -0.5rem;
     }
 }