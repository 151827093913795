.flexMap {
    height: 88.2vh;
}

.mapAlone {
    height: 82.8vh;
}

.heatMap {
    height: 88.2vh;
}

.settingsMark {
    height: 460px;
}
.settingsPerimetr{

    height: 440px;
}
.map {
    overflow-y: hidden;
    width: 100%;
    z-Index: 1;
}
.mapBot{
    overflow-y: hidden;
    width: 100%;
    z-Index: 1;
}
.mapNoData {
    height: 1vh;
}

.headerMapFlex { 
    display: flex;
    flex-direction: row;
}

.countAnimal {
    margin-left: 0.5rem;
}

.headerSearchAnimalPageFlex {
    display: flex;
    flex-direction: row;
    width: 20%;
    margin: 5px;
}

.countAnimalFlex {
    display: flex;
    margin-top: 0.6rem;
    margin-left: 1rem;
}

.inputSearch {
    border-radius: 0 !important;
}

.telemetryToggle {
    background: rgba(255, 255, 255, 0.247);
    font-size: 14px;
    margin-left: 1rem;
}

.headerSelectRangeHeatMapAnimalPageFlex {
    margin: 5px;
}

.selectRangeHeatMap {
    border-radius: 0 !important;
    width: 20%;
}

.markCheckHeatMap {
    margin-left: 1rem;
}



.btnSubmitHeatData {
    border-radius: 0;
    margin-left: 1rem;
}

.spinnerPlace {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinnerText {
    margin-top: 1rem;
    margin-bottom: 1rem;

}

.spinner {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: transparent;
    border: solid #285574 3px;
    animation: round 1s linear infinite;
}

.spinner::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 135px;
    height: 135px;
    border-radius: 50%;
    border-top: #81D000 5px solid;
}

.dividerAnimalCard {
    background-color: #81D000;
}

.rangeTimeCont {
    padding: 0px 10px 0px 10px;
}

.headeTextRange {
    font-weight: bold;
}

.btnRangeTime {
    margin-left: 0.5rem;
    border-radius: 0px;
    background-color: #285574;
    color: white;
}

.btnRangeTime:hover {
    background-color: #81D000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    color: white !important;
}

.headeTextRange {
    font-weight: bold;
}

.rangeTime {
    display: flex;
    margin-bottom: 1rem
}

.flexGroupRangeHeat {
    height: 5%;
    display: flex;
    margin-left: -10px;
}

.flexToogleGroup {

    display: flex;
}

.heatmapToggle {
    margin-top: 0.4rem;
    margin-left: 3rem;
}

.treckToggle {
    margin-top: 0.4rem;
    margin-left: 1rem;
}

@keyframes round {
    from {
        rotate: 0deg;
    }

    to {
        rotate: 360deg;
    }
}

@media screen and (max-width : 920px) {

    .flexMap {
        height: 90%;
    }

    .heatMap {
        height: 94.3%;
    }

    .map {
        margin-top: 0.2rem;
        max-height: 100%;
    }

    .mapAlone {
        height: 50vh;
    }
    .mapBot{
        max-height: 90vh;
        overflow-y: scroll;
      
    }
    .animalListHeader {
        margin-top: 0.5rem;
    }
}

@media screen and (max-width : 920px) {

    .rangeTimeCont {
        display: flex;
        padding: 0px;
        justify-content: space-between;
    }

    .animalListHeader {
        margin-top: 0.5rem;
    }
}

@media screen and (max-width : 900px) and (max-height: 510px) and (orientation: landscape) {
    .flexGroupRangeHeat {
        min-width: 600px;
        max-width: 600px;
        height: 5%;
        display: flex;
        margin-left: 0px;
        justify-content: space-between;
    }

    .mapAlone {
        margin-top: 1rem;
        max-height:92%;
        min-height: 92%;
        min-width: 650px;
        max-width: 650px;
    }

}
@media screen and (max-height : 800px) {
    .flexMap {
        height: 88%;
    }

}
@media screen and (max-height : 700px) {
    .flexMap {
        height: 85%;
    }

}
@media screen and (max-width : 800px) {

    .flexGroupRangeHeat {
        display: flex;
        flex-direction: column;
        margin-left: 0px;

        margin-bottom: 4rem;
    }

    .headerSearchAnimalPageFlex {
        width: 97%;
        justify-content: center;
    }

    .selectRangeHeatMap {
        width: 40%;
    }

    .headerMapFlex {
        display: flex;
        flex-direction: column;
    }

    .countAnimalFlex {
        display: flex;
        margin-top: 0rem;
        margin-left: 0.5rem;
        padding-bottom: 0.1rem;
    }

    .rangeTimeCont {
        padding: 0px 10px 0px 10px;
    }

    .flexMap {
        margin-top: 0.5rem;
    }

}