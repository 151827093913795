.textHeaderAddMark {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1rem;
}
.btnCancelAddMark {
    margin-right: 1rem;
}
.btnAddAnimal {
    background-color: #285574!important;
}
.btnAddAnimal:hover {
    background-color: #81D000 !important;
}

.addMark {
    width: 100%;
}

.mapSettings {
    overflow-x: auto;
    width: 100%;
    max-height: 460px;
}
.selectPicture{


}
@media screen and (max-width : 920px) {
    .mapSettings {
     
    }


}
